<template>
  <div class="content-area address-form">
    <hero-bar>
      {{ title }}

      <b-button
        slot="right"
        icon-left="content-save"
        type="is-link"
        :class="{ 'is-loading': isLoading }"
        @click="save"
      >
        {{ $t('save') }}
      </b-button>
    </hero-bar>

    <section class="section is-main-section">
      <card-component>
        <div class="columns">
          <div class="column">
            <b-field :label="$t('addressName')" horizontal>
              <b-input
                v-model="form.name"
                :placeholder="$t('addressName')"
                required
                class="is-fullwidth"
              />
            </b-field>

            <b-field :label="$t('location')" horizontal>
              <gmap-places
                :place.sync="form.location"
                :adress="(form.location.formatted_address) ? form.location.formatted_address : ''"
                :placeholder="$t('chooseLocation')"
              />
            </b-field>

            <b-field label="" horizontal>
              <div v-if="form.location" class="is-user-avatar has-text-centered is-center">
                <gmap-image
                  v-if="form.location.latlng"
                  :latlng="form.location.latlng"
                  :size="[300, 300]"
                />
              </div>
            </b-field>
          </div>

          <div class="column">
            <b-field :label="$t('itemStatus.true')" horizontal>
              <b-switch v-model="form.is_active" />
            </b-field>

            <b-field :label="$t('description')" horizontal>
              <b-input
                v-model="form.description"
                :placeholder="$t('description')"
              />
            </b-field>
          </div>
        </div>
      </card-component>
    </section>
  </div>
</template>

<script>
export default {
  components: {
    HeroBar: () => import('@/components/HeroBar'),
    CardComponent: () => import('@/components/CardComponent'),
    GmapPlaces: () => import('@/components/GMapPlacesInput'),
    GmapImage: () => import('@/components/GMapImage'),
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      isLoading: false,
      form: {
        id: null,
        name: '',
        address: '',
        description: '',
        location: {},
        is_active: true,
      },
    }
  },
  computed: {
    isCreateMode () {
      return !this.id
    },
    title () {
      return this.isCreateMode ? this.$t('createAddress') : this.$t('editAddress')
    }
  },
  mounted () {
    this.$store.commit('setAsideActiveMenuKey', 'addresses.index')

    document.title = this.title

    if (!this.isCreateMode) {
      this.show(this.id)
    }
  },
  beforeDestroy () {
    this.$store.commit('setAsideActiveMenuKey', '')
  },
  methods: {
    save () {
      return this.isCreateMode ? this.store() : this.update(this.id)
    },
    store () {
      this.isLoading = true

      const params = {
        ...this.form,
        address: this.form.location.formatted_address,
        location: this.form.location.latlng,
      }

      this.$http.post('/addresses', params).then(() => {
        this.$buefy.toast.open({
          message: this.$t('created'),
          type: 'is-success',
          duration: 5000,
        })

        this.$router.push({ name: 'addresses.index' })
      }).catch(({ response }) => {
        const errors = this.$_.uniq(this.$_.flattenDeep(this.$_.values(response.data.errors))).join('<br>')

        this.$buefy.toast.open({
          message: `${this.$t('error')}:<br>${errors}`,
          type: 'is-danger',
          queue: false,
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
    update (addressId) {
      this.isLoading = true

      const params = {
        ...this.form,
        address: this.form.location.formatted_address,
        location: this.form.location.latlng,
      }

      this.$http.put('/addresses/' + addressId, params).then(() => {
        this.$buefy.toast.open({
          message: this.$t('updated'),
          type: 'is-success',
          duration: 5000,
        })

        this.$router.push({ name: 'addresses.index' })
      }).catch(({ response }) => {
        const errors = this.$_.uniq(this.$_.flattenDeep(this.$_.values(response.data.errors))).join('<br>')

        this.$buefy.toast.open({
          message: `${this.$t('error')}:<br>${errors}`,
          type: 'is-danger',
          queue: false,
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
    show (addressId) {
      this.showSpinner()
      this.isLoading = true

      this.$http.get('/addresses/' + addressId).then(({ data }) => {
        this.form.id = data.data.id
        this.form.name = data.data.name
        this.form.location.formatted_address = data.data.address
        this.form.location.latlng = data.data.location
        this.form.description = data.data.description
        this.form.is_active = data.data.is_active
      }).catch(() => {
        this.$buefy.toast.open({
          message: this.$t('error'),
          type: 'is-warning',
        })
        
        this.$router.push({ name: 'addresses.index' })
      }).finally(() => {
        this.hideSpinner()
        this.isLoading = false
      })
    },
  },
}
</script>